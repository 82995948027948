import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import LogoWhite from "../images/icons/logo_white.jpg"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Foot from "../components/foot"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import AthenaFront from "../images/athena/front.jpg"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Helmet>
      <meta property="og:title" content="Beautiful senior living facilities serving Greater Temecula Valley and North San Diego" />
      <meta property="og:image" content={ LogoWhite } />
    </Helmet>

    <Container className="mt-4">
      <Row>
        <Col xs={12} md className="mb-4 me-4" style={{fontSize: "13pt"}}>
          <h3>Welcome to <br />Grace Care Home</h3>
          <p className="mt-3 mb-4" style={{textAlign: "justify"}}>
            Our beautiful senior living facilities serving Greater Temecula Valley and North San Diego. Let us be an 
            extended family for your loved ones &mdash; where they are cared for with love, dignity and compassion.
          </p>
          <Link style={{color: "#536eb1", textDecoration: "none"}} to="/our-story">Read our story &rarr;</Link> <br />
          <Link style={{color: "#536eb1", textDecoration: "none"}} to="/our-locations">See our locations &rarr;</Link> <br />
          <Link style={{color: "#536eb1", textDecoration: "none"}} to="/our-services">Explore our services &rarr;</Link> <br />
          <Link style={{color: "#536eb1", textDecoration: "none"}} to="/client-testimonials">Hear from our clients &rarr;</Link>
        </Col>
        <Col xs={12} md className="mt-3">
          <img 
            src={AthenaFront} alt=""
            style={{borderRadius: "3px", boxShadow: "0 0 5px 2px #60b7a4"}} 
          />
        </Col>
      </Row>

      <div style={{marginTop: "170px"}}>
        <Foot />
      </div>

    </Container>
  </Layout>
)

export default IndexPage
